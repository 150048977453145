import React, { useState, useEffect } from 'react';
import './Css/MemberShip.css';
import Image from 'react-bootstrap/Image';
import EbookBanner from '../Components/Assets/Ebook-Banner.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import ToggleTheme from '../Components/ToggleTheme/ToggleTheme';
// import Logo from '../Components/Assets/Logo.svg';
import SvgLogo from '../Components/SvgLogo/SvgLogo';

const Membership = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const root = document.documentElement;
    if (isDarkMode) {
      root.style.setProperty('--fill', 'hsl(0, 0%, 30%)');
      root.style.setProperty('--finish-fill', 'hsl(0, 0%, 0%)');
      root.style.setProperty('--black-text', '#fff');
      root.style.setProperty('--white-text', '#000');
      root.style.setProperty('--white-bg', '#000');
      root.style.setProperty('--black-bg', '#fff');
    } else {
      root.style.setProperty('--fill', 'hsl(0, 0%, 80%)');
      root.style.setProperty('--finish-fill', 'hsl(0, 0%, 89%)');
      root.style.setProperty('--black-text', '#000');
      root.style.setProperty('--white-text', '#fff');
      root.style.setProperty('--white-bg', '#fff');
      root.style.setProperty('--black-bg', '#000');
    }
  }, [isDarkMode]);

  const toggleMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const calculateTimeLeft = () => {
    const endTime = new Date();
    endTime.setHours(23, 59, 59, 999); // Set the offer end time to today 23:59:59
    const now = new Date();
    const difference = endTime - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const showPDF = () => {
    // Example URL, replace with your actual PDF URL
    const pdfURL = 'https://codewithmuhilan.com/Extra-Assets/Frontend%20E-Book.zip';

    // Open PDF in a new tab
    window.open(pdfURL, '_blank');
  };

  const handlePayment = async () => {
    setPaymentLoading(true);

    const options = {
      key: 'rzp_live_pdn4FUtVwEMjYX', // Replace with your Razorpay key
      amount: 19900, // amount in paisa (199.00 * 100)
      currency: 'INR',
      name: 'CodewithMuhilan',
      description: 'E-BooK Payment',
      payment_capture: '1',  // Auto capture payment after successful authorization
      image: 'https://cdn.buymeacoffee.com/uploads/profile_pictures/2023/11/PIXxBAJGpUj1wI7F.png@300w_0e.webp',
      handler: function (response) {
        setShowModal(true);
        setPaymentSuccess(true);
      },
      notes: {
        membership_type: 'premium',
      },
      theme: {
        color: '#528FF0',
      },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();

    setPaymentLoading(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setPaymentSuccess(false);
  };

  const timerComponents = Object.keys(timeLeft).map((interval) => {
    if (!timeLeft[interval]) {
      return null;
    }

    return (
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  // Text morphing effect logic for the span
  const [textIndex, setTextIndex] = useState(0);
  const spanTexts = [
    "Attention Coders!",
    "Get Ready!",
    "New Offer!",
    "Don't Miss!",
    "Special Deal!"
  ];
  const [currentSpanText, setCurrentSpanText] = useState(spanTexts[0]);
  const [nextSpanText, setNextSpanText] = useState(spanTexts[1]);
  const spanMorphTime = 1;
  const spanCooldownTime = 0.25;
  const [spanMorph, setSpanMorph] = useState(0);
  const [spanCooldown, setSpanCooldown] = useState(spanCooldownTime);

  useEffect(() => {
    const spanTimer = setInterval(() => {
      if (spanCooldown > 0) {
        setSpanCooldown(prev => prev - 0.01);
      } else {
        setSpanMorph(prev => prev + 0.01);
        if (spanMorph >= 1) {
          setSpanCooldown(spanCooldownTime);
          setSpanMorph(0);
          setTextIndex(prev => (prev + 1) % spanTexts.length);
          setCurrentSpanText(spanTexts[textIndex % spanTexts.length]);
          setNextSpanText(spanTexts[(textIndex + 1) % spanTexts.length]);
        }
      }
    }, 10);

    return () => clearInterval(spanTimer);
  }, [spanCooldown, spanMorph, textIndex, spanTexts]);

  return (
    <div id="member-container" className="content-view d-flex flex-column justify-content-center align-items-center">

      <header>
        <div style={{ backgroundColor: 'transparent', background: 'transparent' }}>
          {/* <Logo width={50} height={50} /> */}
          {/* <svg className='logoImg' src={Logo} width={50} height={50}  /> */}


          {/* <SvgLogo /> */}


          <h1 className='MainLogo' >MuhilanOrg</h1>
        </div>

        <div className="toggle-container">
          <input
            type="checkbox"
            id="mode-toggle"
            className="toggle-checkbox"
            checked={isDarkMode}
            onChange={toggleMode}
          />
          <label htmlFor="mode-toggle" className="toggle-label" >
            <span className="toggle-ball" />
          </label>
        </div>
      </header>




      {/* <ToggleTheme /> */}

      <h1 style={{ fontSize: "clamp(1rem, 2vw + 1rem, 8rem)" }} className='Course-Title'>Master Html, CSS, JavaScript,
        ReactJS, From Beginner To Advance</h1>

      <Image className="Course-Banner-Image" src={EbookBanner} fluid />
      <p className='Course-Para'>
        <span className='Course-Para-Span' style={{ fontWeight: '800', position: 'relative', top: '-15px' }}>
          <div id="span-container">
            <span id="span-text1" style={{ opacity: 1 - spanMorph }}>🌟{currentSpanText}</span>
            <span id="span-text2" style={{ opacity: spanMorph }}>{nextSpanText}</span>
          </div>
        </span> <br />

        Are you ready to elevate your frontend development skills? Look no further than the comprehensive eBook from CodewithMuhilan. This meticulously crafted guide is perfect for both beginners and advanced developers. Created with dedication and expertise, this eBook offers clear and concise explanations, accompanied by practical examples, covering HTML, CSS, JavaScript, ReactJS, and essential mathematical concepts.
        <br /><br />
        What sets this eBook apart is its practical approach, featuring over 300 project ideas and more than 250 source codes, providing you with ample opportunities to practice and solidify your knowledge. Think of it as having an expert coding mentor right by your side, guiding you through each step of your learning journey.
        <br /><br />
        Join the community of passionate learners and dive into the exciting world of frontend development with CodewithMuhilan. Together, let's explore, create, and innovate in the realm of coding! 🚀
      </p>

      <button className="btn-shake" onClick={handlePayment} disabled={paymentLoading}>
        {paymentLoading ? 'Processing Payment...' : 'Get Now At '}
        <span style={{ textDecoration: 'line-through' }}>₹1,999.00</span>
        ₹199.00/- Only
      </button>

      <span className='offer-text'>
        🎉 90% OFF, Only for today
        <FontAwesomeIcon icon={faClock} style={{ marginRight: '5px', marginLeft: '10px' }} />
        {timerComponents.length ? (
          <span>
            {" "}
            {timerComponents}
          </span>
        ) : (
          <span> - Offer ended!</span>
        )}
        
      </span>




      {/* <div className='bar-line'></div> */}




      <a style={{ textDecoration: 'none' }} target='_blank' href="https://buymeacoffee.com/codewithmuhilan/e/279126
">
        <button className="btn-shake"
          // onClick={handlePayment} disabled={paymentLoading} 
          style={{ fontSize: '15px' }}>

          {paymentLoading ? 'Processing Payment...' : 'International Payment✈️ '}
          {/* <span style={{ textDecoration: 'line-through' }}>₹1,999.00</span> */}
          {/* ₹199.00/- Only */} Click Me
        </button>
      </a>

      <span className='offer-text'>
        International cards and Wallets accepted here..☝
      </span>


      <div className="more-details-container" >
        <h1>What Inside this E-BooK :</h1>
        <div className='detail-inner-container'>
          <div className="details details-1">
            <h2 className='datail-heading'>Languages </h2>
            <ul className='details-ul'>
              <li className='details-li'>HTML</li>
              <li className='details-li'>CSS</li>
              <li className='details-li'>JavaScript</li>
              <li className='details-li'>React JS</li>
            </ul>
            <span class="card__cta cta" onClick={handlePayment} disabled={paymentLoading}>Buy Now..!</span>

          </div>

          <div className="details details-2">
            <h2 className='datail-heading'>Projects: </h2>
            <p>There are more than 75+ web related projects in this E-BooK.</p>
            <span class="card__cta cta" onClick={handlePayment} disabled={paymentLoading}>Buy Now..!</span>
          </div>

          <div className="details details-3">
            <h2 className='datail-heading'>Interview qn: </h2>
            <p>There are more than 250+ web related interview questions in this E-BooK.</p>
            <span class="card__cta cta" onClick={handlePayment} disabled={paymentLoading}>Buy Now..!</span>
          </div>

          <div className="details details-4">
            <h2 className='datail-heading'>Handwritten Notes: </h2>
            <p>In this E-book there are handwritten notes for college student you can reffer.</p>
            <span class="card__cta cta" onClick={handlePayment} disabled={paymentLoading}>Buy Now..!</span>
          </div>

          <div className="details details-5">
            <h2 className='datail-heading'>Free Bonus: </h2>
            <p>I just made some interview questions on Html, Css, JavaScript and also notes on Quantitative Aptitude..</p>
            <span class="card__cta cta" onClick={handlePayment} disabled={paymentLoading}>Buy Now..!</span>
          </div>

        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Payment Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Thank you for your purchase! Your payment has been successfully processed.</p>
          <p>Click the button below to view or download your PDF.</p>
          <Button variant="primary" style={{ marginRight: '15px' }} onClick={showPDF}>
            Download E-BooK
          </Button>
          <Button variant="primary" onClick={showPDF}>
            View E-BooK
          </Button>

          <p style={{ margin: '15px', marginTop: '30px', marginLeft: '5px' }}>⚠️Kindly make sure to Download the E-Book before closing this tab.</p>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Membership;
