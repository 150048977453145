// import React, { useState } from 'react';
// import { BrowserRouter as Router, Routes, Route, Link, NavLink, Navigate } from 'react-router-dom';
// import Home from './Client/Screens/Home';
// import Shop from './Client/Screens/Shop';
// import MemberShip from './Client/Screens/MemberShip';
// // import Donate from './Client/Screens/Donate';
// // import User from './Client/Screens/User';
// // import Cart from './Client/Components/Cart/Cart';
// import './App.css';
// // import { HiMiniShoppingBag } from "react-icons/hi2";
// // import { RiAccountPinCircleFill } from "react-icons/ri";
// import { FaBars, FaTimes } from "react-icons/fa";

// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import Footer from './Client/Components/Footer/Footer';

// function App() {
//   const [expanded, setExpanded] = useState(false);

//   const handleToggle = () => {
//     setExpanded(!expanded);
//   }

//   const closeMenu = () => {
//     setExpanded(false);
//   }

//   return (
//     <Router basename='/'>
//       <div>
//         {/* <Navbar expanded={expanded} className='bg' bg="white" variant="dark" expand="lg"> */}
//           <Container className='navbar'>
//             <Navbar.Brand as={Link} to="/" className='logo'>
//               {/* Muhilan Org */}
//               </Navbar.Brand>
//             {/* <Navbar.Toggle className='bar-icon' onClick={handleToggle} aria-controls="responsive-navbar-nav">
//               {expanded ? <FaTimes /> : <FaBars />}
//             </Navbar.Toggle> */}
//             <Navbar.Collapse id="responsive-navbar-nav">
//               <Nav className="me-auto" 
//               // onClick={closeMenu}
//               >
//                 {/* <Nav.Link className='link' as={NavLink} to="/" onClick={closeMenu} activeClassName="active-link" exact>Home</Nav.Link> */}
//                 {/* <Nav.Link className='link' as={NavLink} to="/shop" onClick={closeMenu} activeClassName="active-link">Shop</Nav.Link> */}
//                 {/* <Nav.Link className='link' as={NavLink} to="/donate" onClick={closeMenu} activeClassName="active-link">Donate</Nav.Link> */}
//                 <Nav.Link className='link' as={NavLink} to="/membership" onClick={closeMenu} activeClassName="active-link">
//                 {/* E-BooK */}
//                 </Nav.Link>
//                 {/* <Nav.Link className='link' as={NavLink} to="/user" onClick={closeMenu} activeClassName="active-link"><RiAccountPinCircleFill /></Nav.Link> */}
//                 {/* <Nav.Link className='link' as={NavLink} to="/cart" onClick={closeMenu} activeClassName="active-link"><HiMiniShoppingBag /></Nav.Link> */}
//               </Nav>
//             </Navbar.Collapse>
//           </Container>
//         {/* </Navbar> */}

//         <Routes>
//           <Route path="/" element={<Navigate to="/membership" />} />
//           {/* <Route path="/shop" element={<Shop />} /> */}
//           {/* <Route path="/donate" element={<Donate />} /> */}
//           <Route path="/membership" element={<MemberShip />} />
//           {/* <Route path="/cart" element={<Cart />} /> */}
//           {/* <Route path="/user" element={<User />} /> */}
//         </Routes>

//         {/* <Footer /> */}
//       </div>
//     </Router>
//   );
// }

// export default App;


import React from 'react';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import MemberShip from '../src/Client/Screens/MemberShip';

const App = () => {
  return (
    <div 
    // style={{backgroundColor:'#000'}}
    >
    <MemberShip />
    </div>
  );
};

export default App;
